<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter"
              parallax-active="true"
              :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              <img :src="image_head_title"/>
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div v-if="isLoading" style="height: 200px">
            <div id="loading">
              <div class="spinner"></div>
            </div>
          </div>
          <div v-else>
            <TermsList/>
            <div v-if="{}.hasOwnProperty.call(servicePolicy, 'id')">
              {{servicePolicy.date}}<br>
              {{servicePolicy.title}}<br>
              <p v-html="servicePolicy.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Mixins from "@/plugins/basicMixins";

  import TermsList from "@/pages/terms/sub/TermsList";

  export default {
    mixins: [Mixins.HeaderImage],
    bodyClass: "ac-service-policy",
    components: {
      TermsList,
    },
    computed: {
      ...mapGetters([
        "locale",
        "servicePolicy",
        "isLoading",
      ]),
    },
    mounted() {
      this.GET_SERVICE_POLICY_DATA();
    },
    watch: {
      locale() {
        this.GET_SERVICE_POLICY_DATA();
      }
    },
    methods: {
      ...mapActions([
        'GET_SERVICE_POLICY_DATA',
      ]),
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/pages/news/04_news_bg1-2.png"),
        image_head_title: require("@/assets/img/pages/terms/service-policy.png"),
      };
    },
  }
</script>

<style lang="scss">
  .ac-service-policy {
    .main-raised {
      background-color: #18182f;
      min-height: 50vh;

      .section {
        padding: 50px 0;
        color: white;
      }
    }
  }
</style>