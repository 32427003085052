<template>
  <div class="ac-terms-list">
    <div class="flex flex-direction-row space-between">
      <nav class="ac-terms">
        <ul>
          <li>
            <router-link to="/termsofuse" :class="$route.path === '/termsofuse' ? 'select' : ''" >
              {{language.common_footer_TermsOfUse}}
            </router-link>
          </li>
          <li>
            <router-link to="/privacypolicy" :class="$route.path === '/privacypolicy' ? 'select' : ''" >
              {{language.common_footer_PrivacyPolicy}}
            </router-link>
          </li>
          <li>
            <router-link to="/servicepolicy" :class="$route.path === '/servicepolicy' ? 'select' : ''" >
              {{language.common_footer_ServicePolicy}}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "TermsList.vue",
    computed: {
      ...mapGetters([
        'language',
      ]),
    },
  }
</script>

<style lang="scss">
  .ac-terms-list{
    margin-top: -58px;
    padding: .9375rem 0;
    text-align: center;
    display: -webkit-flex; /* Safari */ /* Safari 6.1+ */
    display: flex;
    justify-content: right;

    .ac-terms {
      padding: 1px;
      ul{
        margin: 0;
        padding: 0;
        list-style: none;

        li{
          display: inline-block;

          a{
            color: #cdabff !important;
            //color: inherit !important;
            padding: .9375rem;
            // font-weight: $font-weight-bold;
            // font-size: $mdb-btn-font-size-base;
            text-transform: uppercase;
            // border-radius: $border-radius-base;
            text-decoration: none;
            position: relative;
            display: block;

            &:hover{
              text-decoration: none;
            }

            &.select {
              color: #77eaff !important;
            }
          }

          .btn{
            margin: 0;
          }
        }

        &.links-horizontal{
          &:first-child a{
            padding-left: 0;
          }

          &:last-child a{
            padding-right: 0;
          }
        }

        &.links-vertical{
          li{
            display: block;
            margin-left: -5px;
            margin-right: -5px;

            a{
              padding: 5px;
            }
          }
        }
      }
    }


    .social-buttons{
      a,
      .btn{
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0;
      }
    }

    .footer-brand{
      padding: 15px;
      font-size: 18px;
      margin-left: -15px;

      &,
      &:hover,
      &:focus{
        // color: $black-color !important;
      }
    }
    .copyright{
      padding: 15px 0;
      .md-icon {
        font-size: 18px !important;
        color: inherit !important;
      }
    }

    nav ul li a {
      margin-left: -15px;
    }
  }

</style>